import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import wedding_gift_img from './img/wedding_gift.jpeg';
import participation_img from './img/participation.jpeg';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function MainView() {
  return (
  <Box sx={{ flexGrow: 1, mt: 4 }}>
  <Grid container spacing={3}>
  <Grid item xs={12} md={12} style={{textAlign: "center"}}>
  <Typography gutterBottom variant="h4" component="div">
            Mariage de Maïté et Nicolas
  </Typography>
  <Typography variant="h6" component="div"> {/* Smaller font size for the date */}
            14/09/2024
  </Typography>
  </Grid>
  <Grid item xs={12} md={6} style={{display: 'flex'}}>
    <Grid container justifyContent={{md: "flex-end", xs: "center"}}>
    {ParticipationCard()}
    </Grid>
  </Grid>
  <Grid item xs={12} md={6} style={{display: 'flex'}}>
    <Grid container justifyContent={{md: "flex-start", xs: "center"}}>
    {WeddingGiftCard()}
    </Grid>
  </Grid>
</Grid>
</Box>)
}

function WeddingGiftCard() {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea href="https://www.kadolog.com/fr/list/liste-de-mariage-de-maite-nicolas-852699">
        <CardMedia
          component="img"
          height="200"
          image={wedding_gift_img}
          alt="wedding_gift"
          style={{ justifyContent: "center", display: "flex" }}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Liste de mariage
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Votre présence sera notre plus beau cadeau, toutefois, si vous désirez contribuer à la concrétisation de nos futurs projets, cliquez ici. 
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

function ParticipationCard() {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea href="https://withjoy.com/nicolas-and-maite/rsvp">
        <CardMedia
          component="img"
          height="200"
          image={participation_img}
          alt="wedding_gift"
          style={{ justifyContent: "center", display: "flex" }}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Confirmer votre présence
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Merci de nous confirmer votre présence avant le 1er août 2024 en cliquant ici. 
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default MainView;